import React from "react"
import Markdown from "react-markdown";

import {graphql, useStaticQuery} from 'gatsby'

import Layout from '../containers/layout'

import AboutHero from '../components/about-hero'
// import in alpha order but with breadcrumb, since it also is used in blogpost->article-header this conflicts with the order on templates/page.js so importing before... consider having a different breadcrumb for pages and one for blog post preview such as blog-post-preview-breadcrumb ;)
import Breadcrumb from '../components/breadcrumb'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import DynamicZoneComponent from '../components/dynamic-zone-component'
import MetaSchemaSEO from '../components/seo';


export const query = graphql`
query {

  strapiSiteSetting {
    site_name
    description: default_seo_description
  }
  strapiAboutSetting {
    about_us_page {
      data {
        about_us_page
      }
    }
  }
  strapiPage(slug: {eq: "about"}) {
    slug
    title
    title_short
    excerpt
    publishedAt
    createdAt
    updatedAt
    page_type {
      name
      slug_prefix
    }
    parent_page {
      title
      title_short
      slug
      include_parent_when_in_breadcrumb_as_parent
      parent_page {
        title_short
        slug
      }
    }
    main_image {
      url
      caption
      alternativeText
      localFile{
        childImageSharp{
          gatsbyImageData(
            quality: 70,
            width: 350,
            aspectRatio: 1,
            transformOptions: {cropFocus:ENTROPY})
        }
      }
    }
    childPages: children_pages {
      id
      slug
      primary_topic
      title
      title_short
      excerpt
      page_value
      updatedAt
      parent_page {
        title
        title_short
        slug
      }
      page_type {
        name
        slug_prefix
      }
      main_image {
        url
        caption
        alternativeText
        localFile{
          childImageSharp{
            gatsbyImageData(
              quality: 70,
              width: 350,
              aspectRatio: 1,
              transformOptions: {cropFocus:ENTROPY})
          }
        }
      }
    }
    body {
      __typename

      ... on STRAPI__COMPONENT_RELATED_CALLOUT_PAGES {
        pages {
          slug
          primary_topic
          page_type {
            name
            slug_prefix
          }
          site_section: page_type {
            name
            slug_prefix
          }
          title
          title_short
          excerpt
          page_value
          updatedAt
          parent_page {
            title
            title_short
            slug
            page_type {
              name
              slug_prefix
            }
          }
          main_image {
            url
            caption
            alternativeText
            localFile{
              childImageSharp{
                gatsbyImageData(
                  quality: 70,
                  width: 350,
                  aspectRatio: 1,
                  transformOptions: {cropFocus:ENTROPY})
              }
            }
          }
        }
      }
      ... on STRAPI__COMPONENT_MEDIA_IMAGE {
        display_type
        single_image {
          url
          caption
          alternativeText
          localFile{
            childImageSharp{
              gatsbyImageData(
                quality: 70,
                width: 900
              )
            }
          }
        }
      }
      ... on STRAPI__COMPONENT_SEGMENTS_RICH_TEXT {
        id
        strapi_component
        text {
          data {
            text
          }
        }
      }
      
      ... on STRAPI__COMPONENT_SEGMENTS_QUESTION_AND_ANSWER {
        id
        question
        answer {
          data {
            answer
          }
        }
      }
      ... on STRAPI__COMPONENT_SEGMENTS_LIST_TOP_N {
        id
        strapi_component
        header_level
        title
        description {
          data {
            description
          }
        }
        introduction {
          data {
            introduction
          }
        }
      }
      ... on STRAPI__COMPONENT_MEDIA_HTML {
        id
        raw_html
        outer_container
      }
    }
    executive_summary {
      data {
        executive_summary
      }
    }
    body_introduction {
      data {
        body_introduction
      }
    }
    subtitle
  }
}
  `;
  
  

// markup
const AboutPage = () => {

  const data = useStaticQuery(query);
  const article = data.strapiPage || false // hard coded to the 'about' page

  return (
    <Layout>
      <MetaSchemaSEO
        title = {article.title || "About"}
        article = {article}
      />
      <div>
          <Container>
              <Breadcrumb thisPage={article}></Breadcrumb>
              <h1>{article.title}</h1>
          </Container>
      </div>
      <AboutHero></AboutHero>
      {!article && data.strapiAboutSetting !=null && data.strapiAboutSetting.about_us_page && 
        <Container>
          <Markdown children={data.strapiAboutSetting.about_us_page.data.about_us_page} skipHtml={true} />
        </Container>
      }
      { article &&  
        <div>

          { article.body_introduction && article.body_introduction.data && 
          <div><Container>
              <Markdown children={article.body_introduction.data.body_introduction} skipHtml={true} />
          </Container></div>
          }
          
          {
            // add children posts if any
            article.childPages && article.childPages.length > 0 && (
                <Container><BlogPostPreviewList
                    nodes={article.childPages}
                    max_n="100"
                    page_type={article.page_type}
                    ad_ids={ad_ids}
                    page_has_parent={article}
                /></Container>
            )}
          {
              // display body components
              article.body && article.body.map((component, index) => {
                  return (component && 
                      <Container>
                        <div>
                          <DynamicZoneComponent component={component}></DynamicZoneComponent>
                        </div>
                      </Container>
                  )
              }
              )
          }
          <div><Container>
          <Markdown children={article.body_conclusion} skipHtml={true} />
          </Container></div>
        </div>
      }
    </Layout>
  )
}

export default AboutPage
